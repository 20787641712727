const htFetch = async function(api, data) {
  const options = {
    method: data ? 'POST' : 'GET',
  };

  if (data) {
    options.body = JSON.stringify(data);
  }

  const handleErrors = (response) => {
    if (!response.ok) throw Error(response.statusText);
    return response;
  };

  const fetched = await fetch(api, options)
    .then(handleErrors)
    .then(response => response.json())
    .then(fetchedData => {
      const returnObj = { success: true };
      if (Object.keys(fetchedData).length) returnObj.data = fetchedData;
      return returnObj;
    })
    .catch(err => ({ message: err }));

  return fetched;
};

export default htFetch;
